import {EntityState} from '@ngrx/entity';
import {BerechnungsregelMengeMalEinzelpreisDTO, ProduktDTO} from '../../openapi/fakturierung-openapi';


type ProduktDTOEntityState = EntityState<ProduktDTO> & { ids: string[] };


export const produktEntitiesMock: ProduktDTOEntityState = {
  entities: {
    '1': {
      id: '1',
      betriebId: '3982cf7f-4c03-496d-9099-a0fe11792be4',
      bezeichnung: 'Das ist ein Produkt A, das sehr gut ist.',
      nummer: 1001,
      beschreibung: 'Dies ist Produkt A',
      berechnungsregel: {} as BerechnungsregelMengeMalEinzelpreisDTO,
    },
    '2': {
      id: '2',
      betriebId: '3982cf7f-4c03-496d-9099-a0fe11792be4',
      bezeichnung: 'Das Produkt B ist auch nicht schlecht. Es bietet zahlreiche Funktionen und eine solide Leistung, die es zu einer wertvollen Wahl für viele Anwendungsbereiche macht.',
      nummer: 1002,
      beschreibung: 'Dies ist Produkt B',
      berechnungsregel: {} as BerechnungsregelMengeMalEinzelpreisDTO,
    },
    '3': {
      id: '3',
      betriebId: '3982cf7f-4c03-496d-9099-a0fe11792be4',
      bezeichnung: 'Das Produkt C ist der Hammer! Top-Qualität, easy zu bedienen und sieht verdammt gut aus – wer braucht da noch was anderes? Es bietet nicht nur exzellente Verarbeitung und modernes Design, sondern auch Funktionen, die den Alltag erleichtern und es zu einem echten Hingucker machen. Ein Must-Have für alle, die Qualität und Stil schätzen!',
      nummer: 1002,
      beschreibung: 'Dies ist Produkt B',
      berechnungsregel: {} as BerechnungsregelMengeMalEinzelpreisDTO,
    },
  },
  ids: [
    '1', '2', '3'
  ],
};

export const nachgeladenesProdukt: ProduktDTO = {
  id: '999',
  betriebId: '3982cf7f-4c03-496d-9099-a0fe11792be4',
  bezeichnung: 'Osterei',
  nummer: 1,
  beschreibung: 'Bunte Ostereier in Gang 4',
  berechnungsregel: {} as BerechnungsregelMengeMalEinzelpreisDTO,
};
